import React from 'react'
import '../App.css'
import { Link ,useNavigate } from 'react-router-dom'
import LogoWhite from '../images/LogoWhite.svg'
import LogoRed from '../images/chelsea-login-logo.png'
import Facebook from '../images/facebook.svg'
import Twitter from '../images/twitter.svg'
import Instagram from '../images/instagram.svg'
import SettingsIcon from '../images/settings-icon.svg'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import secureLocalStorage from 'react-secure-storage'
import axios from "axios";


export default function DashboardHeader(props) {
    const [scroll, setScroll] = React.useState(false);
    const [Sidebar,setSidebar] = React.useState(false);
    const navigate = useNavigate();

    const siteId = process.env.REACT_APP_SITE_ID;
    const APIURL = process.env.REACT_APP_API_URL;
  
    if(!secureLocalStorage.getItem('LogObject')){
      console.log('Logout');
      navigate('/login');
    }
  
    React.useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 10);
      });
      if(!secureLocalStorage.getItem('LogObject')){
        console.log('Logout');
        navigate('/login');
      }
    }, []);

    function logout(){
      secureLocalStorage.clear();
      axios.delete(`${process.env.REACT_APP_API_URL}/auth/logout`);
      navigate('/');
    }

  return (
    <>
    <header className={scroll ? "site-header dashboard-header font-face-sh py-4 position-sticky top-0 bg-white z-index-9" : "site-header dashboard-header font-face-sh py-4"}>
      <div className='container'>
        <div className='inner-header row d-flex align-items-center'>
          <div className='logo col-sm-6 col-md-6 col-lg-6 col-6'>
            <Link to={"/"}><img className='start-0' src={LogoRed} alt="logo-white" style={{width: 180}} /></Link>
          </div>
          <div className='dashboard-menu col-md-6 d-flex justify-content-end align-items-center'>
            {/* <Link to={"/login"} className='text-white text-decoration-none fs-6 font-face-sh-bold'>Login</Link>
            <Link to={"/signup"} className='py-3 px-5 border border-2 border-white rounded-pill text-white text-decoration-none fs-6 ms-4 lh-1 font-face-sh-bold'>Join Now</Link> */}
            <DropdownButton
              align="end"
              title="Account"
              id="dropdown-menu-align-end"
            >
              <Dropdown.Item eventKey="1" href="/account">My Impact</Dropdown.Item>
              <Dropdown.Item eventKey="2" href="/account/prizes">Prizes</Dropdown.Item>
              <Dropdown.Item eventKey="3" href="/account/subscription">Manage Subscription</Dropdown.Item>
              <Dropdown.Item eventKey="6" href="/account/payment-method">Manage Payment</Dropdown.Item>
              <Dropdown.Item eventKey="4" href="/account/details">Settings</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item eventKey="5" onClick={logout} >Log out</Dropdown.Item>
            </DropdownButton>
            <Link to={"/subscription-plan"} className='py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 ms-4 lh-1 font-face-sh'>Enter The Draw</Link>
          </div>
          <div className='col-sm-6 col-md-6 col-6 mobile-menu justify-content-end d-flex align-items-center d-none'>
            <Link className='for-mobile me-4 d-none' to={"/account/details"}><img src={SettingsIcon} style={{width: 24}} /></Link>
            <div className={Sidebar ? "MenuButton_rootActive":"MenuButton_root"} onClick={() => setSidebar(!Sidebar)}>
              <div className={Sidebar ? "MenuButton_firstLineActive":"MenuButton_firstLine"}></div>
              <div className={Sidebar ? "MenuButton_secondLineActive":"MenuButton_secondLine"}></div>
              <div className={Sidebar ? "MenuButton_thirdLineActive":"MenuButton_thirdLine"}></div>
            </div>
          </div>
        </div>

        <div className='account-navigation pt-3'>
            <ul className='navigation-list list-unstyled d-flex align-items-center justify-content-evenly bg-grey mx-auto rounded-pill' style={{maxWidth: 500, height: 40}}>
                <li className={props.active === 'account' ? 'navigation-item active rounded-pill bg-branding-1 h-100 d-flex align-items-center justify-content-center flex-grow-1 flex-basis-0':'navigation-item h-100 d-flex align-items-center justify-content-center flex-grow-1 flex-basis-0'}>
                    <Link className={`navigation-item-link w-100 h-100 d-flex align-items-center justify-content-center rounded-pill text-decoration-none fs-14 fw-normal ${props.active === 'account' ? 'text-light':'text-black'}`} to={"/account"}>My Impact</Link>
                </li>
                <li className={props.active === 'prize' ? 'navigation-item active rounded-pill bg-branding-1 h-100 d-flex align-items-center justify-content-center flex-grow-1 flex-basis-0' : 'navigation-item h-100 d-flex align-items-center justify-content-center flex-grow-1 flex-basis-0'}>
                    <Link className={`navigation-item-link w-100 h-100 d-flex align-items-center justify-content-center rounded-pill text-decoration-none fs-14 fw-normal ${props.active ==="prize" ? 'text-light':'text-black'}` } to={"/account/prizes"}>Prizes</Link>
                </li>
                <li className={props.active === 'subscription' ? 'navigation-item active rounded-pill bg-branding-1 h-100 d-flex align-items-center justify-content-center flex-grow-1 flex-basis-0' : 'navigation-item h-100 d-flex align-items-center justify-content-center flex-grow-1 flex-basis-0'}>
                    <Link className={`navigation-item-link w-100 h-100 d-flex align-items-center justify-content-center rounded-pill text-decoration-none fs-14 fw-normal ${props.active === 'subscription' ? 'text-light':'text-black'}`} to={"/account/subscription"}>Subscription</Link>
                </li>
                <li className={props.active === 'payment-method' ? 'navigation-item active rounded-pill bg-branding-1 h-100 d-flex align-items-center justify-content-center flex-grow-1 flex-basis-0' : 'navigation-item h-100 d-flex align-items-center justify-content-center flex-grow-1 flex-basis-0'}>
                    <Link className={`navigation-item-link w-100 h-100 d-flex align-items-center justify-content-center rounded-pill text-decoration-none fs-14 fw-normal ${props.active === 'payment-method' ? 'text-light':'text-black'}`} to={"/account/payment-method"}>Payment</Link>
                </li>
                <li className={props.active === 'details' ? 'navigation-item active rounded-pill bg-branding-1 settings h-100 d-flex align-items-center justify-content-center flex-grow-1 flex-basis-0' : 'navigation-item settings h-100 d-flex align-items-center justify-content-center flex-grow-1 flex-basis-0'}>
                    <Link className={`navigation-item-link w-100 h-100 d-flex align-items-center justify-content-center rounded-pill text-decoration-none fs-14 fw-normal ${props.active === 'details' ? 'text-light':'text-black'}`} to={"/account/details"}>Settings</Link>
                </li>
            </ul>
        </div>
      </div>

      <div id="mySidebar" className={Sidebar ? "open sidebar bg-light h-100 top-0 end-0 position-fixed d-flex flex-column ease-in-out overflow-hidden py-4 px-4" : "sidebar h-100 w-0 end-0 top-0 bg-light position-fixed pt-2 ease-in-out overflow-hidden"}  >
        <div className='logo-red col-md-12'>
          <img className='start-0' src={LogoRed} alt="logo-white" style={{width: 180}} />
        </div>
        <div className='mobile-menu-nav col-md-12 mt-5 d-flex flex-column'>
          <Link to={"/subscription-plan"} className='text-center mb-3 py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 lh-1 font-face-sh'>Enter The Draw</Link>
          <div className='fs-6 mb-1' style={{color: '#999'}}><strong>Account</strong></div>
          {/* <Link to={"/login"} className='black-clr text-decoration-none fs-5 font-face-sh-bold branding-1-hover'>Login</Link>
          <Link to={"/signup"} className='py-3 px-5 border border-2 border-branding-1 rounded-pill black-clr text-decoration-none branding-1-hover fs-5 mt-3 w-75 text-center lh-1'>Join Now</Link> */}
          <Link to={"/account"} className='black-clr text-decoration-none fs-5 branding-1-hover py-1'>My Impact</Link>
          <Link to={"/account/prizes"} className='black-clr text-decoration-none fs-5 branding-1-hover py-1'>Prizes</Link>
          <Link to={"/account/subscription"} className='black-clr text-decoration-none fs-5 branding-1-hover py-1'>Manage Subscription</Link>
          <Link to={"/account/payment-method"} className='black-clr text-decoration-none fs-5 branding-1-hover py-1'>Manage Payment</Link>
          <Link to={"/account/details"} className='black-clr text-decoration-none fs-5 branding-1-hover py-1'>Settings</Link>
          <hr />
          <Link onClick={logout} to={"/"} className='black-clr text-decoration-none fs-5 branding-1-hover'>Log Out</Link>
        </div>

        <div className='col-sm-12 col-md-12 col-lg-3 mt-auto'>
            <div className='row'>
              <div className='col-sm-12 col-md-12 col-lg-12 branding-1'>
                <p className='mt-4 pt-2 font-face-sh-bold'>Follow us</p>
                <div className='d-flex'>
                  <a className="black-clr text-decoration-none" href="https://www.facebook.com/ChelseaFC" target='_blank'><img className="me-3" src={Facebook} alt="Facebook" style={{width: 24}} /></a>
                  <a className='black-clr text-decoration-none' href="https://twitter.com/CFCFoundation" target='_blank'><img className='me-3' src={Twitter} alt="Twitter" style={{width: 24}}/></a>
                  <a className='black-clr text-decoration-none' href="https://www.instagram.com/Chelseafc" target='_blank'><img className='me-3' src={Instagram} alt="Instagram" style={{width: 24}}/></a>
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-12 branding-1'>
                <p className='mt-4 pt-2' style={{fontSize: 14}}>Lucky Blues is operated by <a className='text-decoration-none branding-1 branding-1-hover' href="https://playfundwin.com/" target="_blank">Play Fund Win</a></p>
                <div className='d-flex font-face-sh-bold' style={{fontSize: 14}}>
                  <Link className='branding-1 text-decoration-none pe-5 branding-1-hover' to={'/terms-conditions'}>Terms & Conditions</Link>
                  <Link className='branding-1 text-decoration-none branding-1-hover' to={'/privacy-policy'}>Privacy Policy</Link>
                </div>
              </div>
            </div>
          </div>
      </div>

    </header>
    </>
  )
}
