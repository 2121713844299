import React, { Component } from "react";
import Slider from "react-slick";
import TrophyIcon from "../images/trophy-icon.svg";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
      onClick={onClick}
    />
  );
}

export default function TestimonialSlider() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // cssEase: "linear"
  };
  return (
    <div className="story-block-inner bg-white px-5 py-5 shadow-lg rounded-3">
      <Slider {...settings}>
          <div className="story-block">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3">
                  <img className='w-100 rounded-3' src={require('../images/slider/ed.jpg')} alt="jen"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-6">
                  <h4 className="mb-4">Edwin G.</h4>
                  <p style={{fontSize: 14}}>“Thank you Chelsea 50/ 50 for excellent win, it couldn't have been better.”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-3">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">£2439.45</p>
                </div>
              </div>
            </div>
          </div>
          <div className="story-block">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3">
                  <img className='w-100 rounded-3' src={require('../images/slider/andy.jpg')} alt="jonny"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-6">
                  <h4 className="mb-4">Andy P.</h4>
                  <p style={{fontSize: 14}}>“I am absolutely thrilled to have won. Come on the Chelsea!”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-3">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">Hospitality Tickets</p>
                </div>
              </div>
            </div>
          </div>

          <div className="story-block">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3">
                  <img className='w-100 rounded-3' src={require('../images/slider/david.jpg')} alt="steve"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-6">
                  <h4 className="mb-4">David B.</h4>
                  <p style={{fontSize: 14}}>“I play to support the Chelsea FC Foundation and the club I've supported for 30 years!”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-3">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">£1,617.95</p>
                </div>
              </div>

            </div>
          </div>

      </Slider>
    </div>
  );
}
